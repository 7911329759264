import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { fontFamilies, spacing } from '@hultafors/snickers/helpers';

export const Title = styled.p`
  max-width: 1440px;
  width: calc(100vw - 40px);
  margin-inline: auto;
  margin-block: 0;
  font-family: ${fontFamilies.mikro};
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  &:empty {
    display: none;
  }
`;

export const BigPlugsGridStyled = styled.section`
  ${Title} +  & {
    margin-block-start: ${spacing.small};
  }

  max-width: 1440px;
  width: calc(100vw - 40px);
  margin-inline: auto;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;

  > * {
    aspect-ratio: 3 / 4;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);

    > * {
      aspect-ratio: 1 / 1;
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block: 4rem;
  }

  /* @media screen and (min-width: ${breakpoints.desktopMedium}) {
    grid-template-columns: repeat(4, 1fr);
  } */
  @media screen and (min-width: ${breakpoints.desktopLarge}) {
    gap: 30px;
  }
`;
